import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'gatsby'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  root: {
    padding: 6,
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
  },
  titlesection: {
    padding: '0 12px',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  menu: {
    background: '#fff',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 60,
    left: 0,
  }
});

const title = "Literay Plan";

const menu = [
  {label: 'Topics', to: '/topics'},
  {label: 'Definition', to: '/definition'},
  {label: 'Create plan', to: '/create'},
  {label: 'Blog', to: '/blog'},
];

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, };
  }

  handleToggle = event => {
    const {open} = this.state;
    this.setState({ open: !open });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.inner}>
          <div className={classes.titlesection}>
            <Typography variant="h4" gutterBottom>
              <Link
                className={classes.title}
                to="/"
                title="title"
                >{title}</Link>
            </Typography>
          </div>
          <div>
            <IconButton
              onClick={this.handleToggle}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </div>
        { open ? (
          <List>
            {menu.map( item => (
              <ListItem button key={item.label} component={Link} to={item.to}>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
        </List>
        ) : '' }
      </div>
    )
  }
}

const OrdinaryMenu = ({classes}) => {
  return (
    <div className={classes.inner}>
      <div className={classes.titlesection}>
        <Typography variant="h4" gutterBottom>
          <Link
            className={classes.title}
            to="/"
            title="title"
            >{title}</Link>
        </Typography>
      </div>
      <div>
        {menu.map( item => (
          <Button key={item.label} color="inherit" className={classes.button} component={Link} to={item.to}>
            {item.label}
          </Button>
        ))}
      </div>
    </div>
  )
}

OrdinaryMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Navbar = ({classes}) => {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <nav
      className={classes.root}
      role="navigation"
    >
      {matches ? <OrdinaryMenu classes={classes} /> : <MobileMenu classes={classes} />}
    </nav>
  )


}

export default withStyles(styles)(Navbar);
