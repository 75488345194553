import React from 'react'

import Layout from '../../components/Layout'
import TopicsRoll from '../../components/TopicsRoll'
import Header from '../../components/Header'

export default class TopicsIndexPage extends React.Component {
  render() {
  return (
    <Layout
      header={ <Header title="Topics" /> }
      content={ <TopicsRoll /> }
    />
    )
  }
}
