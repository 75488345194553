import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  header: {
    color: '#fff',
    backgroundColor: theme.palette.primary.dark,
    padding: 12,
  },
  title: {
    textAlign: 'center',
  }
});

class Header extends React.Component {
  render() {
    const { classes, title } = this.props;
  return (
    <section className={classes.header}>
      <Typography variant="h4" color="inherit" className={classes.title} gutterBottom>
        {title}
      </Typography>
    </section>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default withStyles(styles)(Header);
