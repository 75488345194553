import React from 'react'
import { Link } from 'gatsby'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'

const grid = 4;
const menu = [
  { label: 'Home', to: '/'},
  { label: 'About', to: '/about'},
  { label: 'Products', to: '/products'},
  { label: 'Form Examples', to: '/contact/examples'},
  { label: 'Contact', to: '/contact'},
  { label: 'Latest Stories', to: '/blog'},
];
const social = [
  { label: 'Facebook', href: 'https://facebook.com', src:facebook},
  { label: 'Twitter', href: 'https://twitter.com', src:twitter},
  { label: 'Instagram', href: 'https://instagram.com', src:instagram},
  { label: 'Vimeo', href: 'https://vimeo.com', src:vimeo},
];

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    padding: 24,
    flexGrow: 1,
    color: '#fff',
  },
  title: {
    color: '#eee',
    textAlign: 'center'
  },
  button: {
    margin: theme.spacing.unit,
    color: '#fff'
  },
  social: {
    margin: 6,
  },
  socialicon: {
    width: '1em',
    height: '1em',
  }
});

const Footer = class extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <footer className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <div className={classes.title}>
              <Typography variant="h4" color="inherit" gutterBottom>
                Literacy Plan
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={grid}>
            <nav>
              { menu.map( item => (
                <Button
                key={item.label}
                className={classes.button}
                component={Link}
                to={item.to}
                target={item.target}
                >{item.label}</Button>
              ))}
            </nav>
          </Grid>
          <Grid item xs={12} sm={grid}>
            <p>LiteracyPlan.com is a result of my master thesis.</p>
          </Grid>
          <Grid item xs={12} sm={grid}>
            <div>
              { social.map( item => (
                <Fab
                  key={item.label}
                  size="small"
                  color="secondary"
                  className={classes.social}
                  href={item.href}
                  >
                    <img
                      src={item.src}
                      alt={item.label}
                      className={classes.socialicon}
                    />
                 </Fab>
              ))}
            </div>
            <br />
            <small><Link to='/admin/' target='_blank' style={{color: 'inherit'}}>Admin</Link></small>
          </Grid>
        </Grid>
      </footer>
    )
  }
}

export default withStyles(styles)(Footer);
