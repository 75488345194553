import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const gutter = 8;

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 18,
    marginBottom: 18,
    marginLeft: -gutter,
  },
  item: {
    padding: gutter,
  },
  article: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    height: 200,
    padding: 18,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  text: {
    color: '#fff'
  }
});


class TopicsRollPlain extends React.Component {
  render() {
    const { data, classes } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className={classes.root}>
        <Grid container>
          {posts && (posts
              .map(({ node: post }, i) => (
                <Grid key={post.id} item xs={12} sm={4} md={3} className={classes.item}>
                  <article className={classes.article}>
                    <Link to={post.fields.slug} style={{color: 'inherit'}}>
                      <Typography variant="h4" color="inherit" gutterBottom>
                        {post.frontmatter.title}
                      </Typography>
                    </Link>
                    <p className={classes.text}>{post.frontmatter.description}</p>
                    <Button color="inherit" className={classes.button} component={Link} to={post.fields.slug}>
                      Keep Reading →
                    </Button>
                  </article>
                </Grid>
              )))}
        </Grid>
      </div>
    );
  }
}

TopicsRollPlain.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

const TopicsRoll = withStyles(styles)(TopicsRollPlain);

export default () => (
  <StaticQuery
    query={graphql`
    query TopicsRollQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] },
        filter: { frontmatter: { templateKey: { eq: "topics" } }}
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              description
              templateKey
            }
          }
        }
      }
    }
    `}
    render={(data, count) => (
      <TopicsRoll data={data} count={count} />
    )}
  />
)
